//
// Lead text
//
.m-lead {
  @include font($font-display-24-wide, $family: true);
  color: $lead-color;
  font-weight: font(base, 'light');

  @include breakpoint(large) {
    @include font($font-display-32-wide);
  }
}
