//
// SVG icons
//

/** ***********
 * DOM wrapper
 */
.c-icon-defs {
  height: 0;
  width: 0;
  overflow: hidden;

  polygon:not(.nofill),
  path:not(.nofill),
  rect:not(.nofill) {
    fill: currentColor;
    overflow: hidden;
  }
}



/** ***********
 * Component
 */
.c-icon {
  @include u-baseline(2, width);
  @include u-baseline(2, height);
  display: inline-block;
  vertical-align: middle;
  transform: translateY(-.1em);

  &:not(.c-icon--nofill) {
    fill: currentColor;
  }

  svg {
    max-width: 100%;
    max-height: 100%;
  }
}
