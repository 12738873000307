//
// Main menu
//


/** ***********
 * Base styles
 */
.m-menu {
  @include u-baseline(8, padding-top);
  background-color: $menu-mobile-background;
  position: fixed;
  top: 0;
  left: -100%;
  bottom: 0;
  width: 100%;
  z-index: 0;

  @include breakpoint(large down) {
    transition: all 0.1s $global-easeInOutExpo;
  }


  @include breakpoint($global-navigation-breakpoint) {
    @include flex;
    background-color: transparent;
    left: 0;
    top: 0;
    padding-top: 0;
    position: relative;
    text-align: right;
  }
}






/** ***********
 * Content
 */
.m-menu__content {
  @include u-baseline(5, padding-bottom);
  height: 100%;
  list-style: none;
  margin: 0;
  padding-left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;

  @include breakpoint($global-navigation-breakpoint) {
    @include flex;
    @include flex-align(right, top);
    height: auto;
    overflow: visible;
    padding-bottom: 0;
    width: 100%;
  }
}










/** ***********
 * Menu list item
 */
.m-menu__item {
  position: relative;

  &:first-child {
    .m-menu__link {
      @include breakpoint($global-navigation-breakpoint) {
        margin-left: 0;
      }
    }
  }
}









/** ***********
 * Menu item wrapper
 */
.m-menu__wrapper {
  @include flex;

  @include breakpoint($global-navigation-breakpoint) {
    display: inline-block;
    padding: 0;
  }
}






/** ***********
 * Item
 */
.m-menu__link {
  @include font($font-display-16, $family: true);
  @include u-link-border(hover);
  @include u-baseline(2, padding-top);
  @include u-baseline(2, padding-bottom);
  @include u-baseline(4, padding-left);
  @include u-baseline(4, padding-right);
  @include u-baseline(7, height);
  background-color: $menu-mobile-item-background;
  color: $menu-mobile-item-color;
  display: block;
  letter-spacing: 1px;
  margin-bottom: rem-calc(2);
  text-transform: uppercase;
  text-align: left;
  width: 100%;

  @include breakpoint($global-navigation-breakpoint) {
    @include font($font-display-18);
    @include u-baseline(4, margin-left);
    @include u-baseline(1, padding-bottom);
    background-color: transparent;
    border-bottom: rem-calc(1) solid transparent;
    display: inline;
    font-weight: normal;
    height: auto;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;

    &:hover {
      color: $menu-mobile-item-hover;
    }
  }
}













/** ***********
 * Modifier: Current item
 *
 * Just bold
 */
.m-menu--current {
  background-color: $menu-current-background;
  color: palette(ui, 'light');

  @include breakpoint($global-navigation-breakpoint) {
    background-color: transparent;
    border-bottom: 1px solid $menu-current-border-color;
    color: $global-body-color;
    font-weight: bold;
  }

  a {
    color: inherit;
  }
}







/** ***********
 * Modifier: Visible for mobile only
 */
.m-menu--mobile {
  @include breakpoint($global-navigation-breakpoint) {
    display: none !important;
  }
}




/** ***********
 * Logo
 */
.m-menu__branding {
  @include u-baseline(3, top);
  @include u-baseline(3, left);
  @include u-baseline(-.5, margin-left);
  position: absolute;

  a {
    display: block;
  }

  svg {
    display: block;
    margin: 0 auto;
    width: rem-calc(105);
    height: rem-calc(28);
    transform: translate3d(0, 0, 0);
    transition: $global-transition;
  }
}









/** ***********
 * Toggle
 */
.m-menu__toggle {
  appearance: none;
  background-color: transparent;
  border: none;
  display: inline-block;
  text-transform: uppercase;

  @include breakpoint($global-navigation-breakpoint) {
    display: none;
  }

  .c-icon {
    @include u-baseline(3, width);
    @include u-baseline(3, height);
  }
}






/** ***********
 * Close icon
 */
.m-menu__close {
  @include u-baseline(2.5, top);
  @include u-baseline(3, right);
  // display: none;
  position: absolute;
  width: rem-calc(19);
  height: rem-calc(19);

  @include breakpoint($global-navigation-breakpoint) {
    display: none !important;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}











/** ***********
 * Checkbox / Checked state
 */
.m-menu__checkbox {
  &:checked ~ .m-menu {
    left: 0;
    opacity: 1;
    visibility: visible;
    z-index: index($elements, menu);
  }
}












/** ***********
 * Submenu
 */
.m-menu__submenu {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;
}

.m-menu__submenu-checkbox:checked {
  ~ .m-menu__submenu {
    display: block;
  }

  ~ .m-menu__submenu-label {
    .c-icon {
      transform: rotate(-180deg);
    }
  }
}







/** ***********
 * Submenu toggle label
 */
.m-menu__submenu-label {
  @include u-baseline(9, width);
  @include u-baseline(7, height);
  @include u-baseline(1.5, padding-top);
  color: $menu-submenu-label-color;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;

  .c-icon {
    @include u-baseline(4, width);
    @include u-baseline(4, height);
  }

  &.m-menu--current {
    color: $menu-submenu-current-color;
  }
}







/** ***********
 * Submenu link
 */
.m-menu__submenu-link {
  @include font($font-display-18, $family: true);
  @include u-baseline(2, padding-top);
  @include u-baseline(2, padding-bottom);
  @include u-baseline(8, padding-left);
  @include u-baseline(3, padding-right);
  background-color: $menu-submenu-item-background;
  color: $menu-submenu-item-color;
  display: block;
  margin-bottom: rem-calc(2);
  text-transform: uppercase;
}






/** ***********
 * Modifier: Submenu current class
 */
.m-menu__submenu--current {
  background-color: $menu-submenu-current-background;
}
