//
// Contacts grid
//
.o-contactgrid {
  @include xy-grid-container;
  @include xy-grid;
  @include u-module-margin;
}











/** ***********
 * Grid item
 */
.o-contactgrid__item {
  @include xy-cell;
  @include u-baseline(4, margin-bottom);

  @include breakpoint(medium) {
    @include xy-cell(6);
    @include flex;
  }

  @include breakpoint(large) {
    @include xy-cell(4);
  }

  @include breakpoint(xlarge) {
    @include xy-cell(3);
  }
}
