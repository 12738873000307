//
// Intro for default pages
//
.o-intro-page {
  @include xy-grid-container;
  @include xy-grid;
  @include u-baseline(3, margin-bottom);
  @include u-baseline(3, padding-top);

  @include breakpoint(large) {
    @include u-baseline(11, padding-top);
    @include u-baseline(6, margin-bottom);
  }
}








/** ***********
 * Title
 */
.o-intro-page__header {
  @include xy-cell;

  @include breakpoint(large) {
    @include xy-cell(9);
  }
}









/** ***********
 * Title
 */
.o-intro-page__title {
  @include font($font-display-28, $family: true);
  @include u-baseline(2, padding-bottom);
  font-weight: font(base, 'semibold');
  border-bottom: rem-calc(4) solid $intro-page-border-color;
  margin: 0;

  @include breakpoint(large) {
    @include font($font-display-48);
  }
}
