//
// Subnavigation
//
.m-subnav {
  list-style: none;
  margin: 0;
  padding: 0;
}









/** ***********
 * List item
 */
.m-subnav__item {
  @include u-baseline(5, padding-left);
  position: relative;

  @include breakpoint(large) {
    @include u-baseline(6, padding-left);
  }

  &:before {
    @include u-baseline(3, width);
    @include u-baseline(3, height);
    background: url('../svg/svg_icon-arrow-right-blue.svg');
    background-size: 100% 100%;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;

    @include breakpoint(large) {
      @include u-baseline(1, top);
    }
  }

  &:not(:last-child) {
    @include u-baseline(3, margin-bottom);
  }

  @include breakpoint(large) {
    &:not(:last-child) {
      @include u-baseline(5, margin-bottom);
    }
  }
}










/** ***********
 * Link
 */
.m-subnav__link {
  @include font($font-display-18, $family: true);
  font-weight: bold;

  @include breakpoint(large) {
    @include font($font-display-24-wide);
  }

  &:hover {
    color: $subnav-link-hover;
  }
}
