// Global base styles
//
//
//

/** **************
 * Basic styles
 */
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}


body {
  background-color: $global-body-background;
  font-family: $global-font-family;
  font-size: 100%;
  line-height: 1.5;
  margin: 0;
  overflow-x: hidden;
  padding: 0;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;

  /**
   * Add breakpoint values to <body> and parse them
   * to js function (breakpoints.js).
   *
   * Source: https://www.lullabot.com/blog/article/importing-css-breakpoints-javascript
   */
  &:before {
    content: 'small';
    display: none;

    @include breakpoint(medium) {
      content: 'medium';
    }

    @include breakpoint(large) {
      content: 'large';
    }

    @include breakpoint(xlarge) {
      content: 'xlarge';
    }

    @include breakpoint(xxlarge) {
      content: 'xxlarge';
    }

    @include breakpoint(xxxlarge) {
      content: 'xxxxlarge';
    }
  }

  &.overflow-hidden {
    overflow: hidden;
  }
}




/** ***********
 * Text selection
 */
::-moz-selection {
  background: palette(brand, 'boticelli');
  color: palette(ui, 'dark');
  text-shadow: none;
}

::selection {
  background: palette(brand, 'boticelli');
  color: palette(ui, 'dark');
  text-shadow: none;
}






/** ***********
 * Palceholder styles
 */
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  @include u-input-placeholder;
}

::-moz-placeholder { /* Firefox 19+ */
  @include u-input-placeholder;
}

:-ms-input-placeholder { /* IE 10+ */
  @include u-input-placeholder;
}

:-moz-placeholder { /* Firefox 18- */
  @include u-input-placeholder;
}




*:focus {
  outline-color: $global-accent-color;
}






/** ***********
 * Links
 */
a:not(img) {
  color: palette(ui, 'dark');
  text-decoration: none;
  // transition: $global-transition;
}







/** ***********
 * Figure reset
 */
figure {
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}









/** ***********
 * Sticky footer
 */
.global-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  overflow-x: hidden;
  position: relative;
}


.global-main {
  flex: 1 0 auto;
  position: relative;

  &:not(.hide-bg) {
    &:before {
      display: none;

      @include breakpoint(xxlarge) {
        @include u-baseline(12, width);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 97 1838' preserveAspectRatio='none'%3E%3Cpath fill='%23CFDFE6' fill-rule='evenodd' d='M21.34 0H0v1837.286h96.146c-26.708-263.834-52.595-587.85-66.969-948.825C18.114 610.63 13.875 310.918 21.34 0'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
      }
    }

  }


  &:after {
    content: '\00a0'; /* &nbsp; */
    display: block;
    height: 0;
    visibility: hidden;
  }
}





/** ***********
 * Stop animations and transitions
 * on window resize
 */
.resize-animation-stopper * {
  animation: none !important;
  transition: none !important;
}
