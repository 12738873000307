//
// Media box
//
.m-mediabox {
  @include xy-grid-container;
  @include xy-grid;
  @include u-module-margin;
  position: relative;
  z-index: 2;
}







/** ***********
 * Modifier: Switched display (text left, media right)
 */
.m-mediabox--switched {
  .m-mediabox__media {
    @include breakpoint($mediabox-breakpoint) {
      order: 2
    }
  }

  .m-mediabox__media-inner {
    @include breakpoint($mediabox-breakpoint) {
      padding-right: 0;
    }
  }

  .m-mediabox__content-inner {
    @include m-mediabox-spacer;
  }
}








/** ***********
 * Media column
 */
.m-mediabox__media {
  @include xy-cell;
  @include u-baseline(3, margin-bottom);

  @include breakpoint($mediabox-breakpoint) {
    @include xy-cell(6);
    margin-bottom: 0;
  }
}








/** ***********
 * Inner container to push
 * right padding
 */
.m-mediabox__media-inner {
  @include m-mediabox-spacer;
}







/** ***********
 * Content column
 */
.m-mediabox__content {
  @include xy-cell;

  @include breakpoint($mediabox-breakpoint) {
    @include xy-cell(6);
  }
}
