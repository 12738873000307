//
// Introbox
//
.m-introbox {
  background: $introbox-background;
}










/** ***********
 * Modifier: Title is splitted (Homepage)
 */
.m-introbox--split {
  .m-introbox__title {
    border: none;

    span {
      display: block;

      &:after {
        @include u-baseline(10, width);
        @include u-baseline(2, margin-top);
        @include u-baseline(2, margin-bottom);
        background-color: palette(brand, 'glacier');
        content: '';
        display: block;
        height: rem-calc(4);
      }
    }
  }
}








/** ***********
 * Header
 */
.m-introbox__header {
  @include u-baseline(2, padding-top);

  @include breakpoint(large) {
    @include flex;
    @include flex-align(left, bottom);
    @include u-baseline(22, min-height);
    padding-top: 0;
    position: relative;
  }

  @include breakpoint(xlarge) {
    @include u-baseline(46, min-height);
    @include u-baseline(6, padding-top);
    @include u-baseline(4, padding-right);
  }
}






/** ***********
 * Title
 */
.m-introbox__title {
  @include font($font-display-28, $family: true);
  @include u-baseline(2, padding-bottom);
  @include u-baseline(3, margin-bottom);
  border-bottom: rem-calc(4) solid $introbox-border-color;
  font-weight: font(base, 'semibold');
  margin-top: 0;
  width: 100%;

  @include breakpoint(medium) {
    @include font($font-display-32);
  }

  @include breakpoint(xlarge) {
    @include font($font-display-48);
    @include u-baseline(3, padding-bottom);
    @include u-baseline(6, margin-bottom);
  }
}







/** ***********
 * Body
 */
.m-introbox__body {
  @include breakpoint(large) {
    @include font($font-base-18-wide);
  }

  p {
    @include u-baseline(3, margin-bottom);
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
