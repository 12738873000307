//
// Grid images
//
.o-grid {
  @include xy-grid-container;
  @include xy-grid;
}








/** ***********
 * Inner container
 *
 * Limit the width of entire grid
 */
.o-grid__inner {
  @include xy-cell;

  @include breakpoint(large) {
    @include xy-cell;
  }

  @include breakpoint(xlarge) {
    @include xy-cell(10);
  }
}








/** ***********
 * Items
 */
.o-grid__items {
  @include xy-grid-container($width: 100%);
  @include xy-grid;
  @include xy-gutters($negative: true);
  width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
}





/** ***********
 * Item
 */
.o-grid__item {
  @include xy-cell(6);
  @include u-module-margin;

  @include breakpoint(medium) {
    @include xy-cell(4);
  }

  @include breakpoint(large) {
    @include xy-cell(4);
  }

  @include breakpoint(xlarge) {
    @include xy-cell(4);
  }
}
