//
// Teaser
//
.m-card {
  @include u-baseline(4, padding-top);
  @include u-baseline(5, padding-bottom);
  @include u-baseline(3, padding-left);
  @include u-baseline(3, padding-right);
  @include flex;
  flex-direction: column;
  background-color: $card-background;
  position: relative;

  @include breakpoint(large) {
    @include u-baseline(6, padding-top);
    @include u-baseline(6, padding-bottom);
  }

  &:hover {
    background-color: $card-hover-background;
    color: $card-hover-color;

    .m-card__title a,
    .c-button {
      color: inherit;
    }

    .c-icon {
      color: currentColor;
    }
  }
}









/** ***********
 * Title
 */
.m-card__title {
  @include font($font-display-28, $family: true);
  @include u-baseline(2, margin-bottom);
  margin-top: 0;

  @include breakpoint(large) {
    @include font($font-display-32);
    @include u-baseline(5, margin-bottom);
  }

  a {
    color: $card-heading-color;

    &:hover {
      color: $global-body-color;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}







/** ***********
 * Body
 */
.m-card__body {
  @include u-baseline(5, margin-bottom);
  flex: 1 0 auto;
  max-width: 100%;

  @include breakpoint(large) {
    @include u-baseline(8, margin-bottom);
  }

  p {
    @include u-baseline(2, margin-bottom);
    margin-top: 0;
  }
}









/** ***********
 * Button
 */
.m-card__button {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  z-index: 1;

  .c-button {
    display: block;
  }
}
