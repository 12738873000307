//
// Statistics
//
.o-statistics {
  @include xy-grid-container;
  @include xy-grid;
  @include flex-align(center, top);
  @include u-module-margin;
}








/** ***********
 * Inner container
 */
.o-statistics__inner {
  @include xy-cell;

  @include breakpoint(large) {
    @include xy-cell(10);
  }

  @include breakpoint(xlarge) {
    @include xy-cell(8);
  }
}









/** ***********
 * Pie charts
 */
.o-statistics__charts {
  @include xy-grid-container;
  @include xy-grid;
  @include xy-gutters($negative: true);
}













/** ***********
 * Chart item
 */
.o-statistics__chart {
  @include xy-cell(6, $gutters: 0);
  @include u-baseline(5, margin-bottom);

  @include breakpoint(medium) {
    @include xy-cell(6, $gutters: 0);
  }

  @include breakpoint(xlarge) {
    @include xy-cell(4, $gutters: 0);
    @include u-baseline(11, margin-bottom);
  }
}
