//
// Image with preview image
//

.c-image {
  background-color: palette(ui, 'alto');
  display: block;
  position: relative;
  overflow: hidden;

  img {
    @include u-img-responsive;

    &.thumb {
      filter: blur(20px);
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      position: absolute;
      z-index: 0;
    }

    &.original {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}








/** ***********
 * Caption
 */
.c-image__caption {
  @include font($font-display-16);
  @include u-baseline(2, margin-top);
  color: $image-caption-color;
}
