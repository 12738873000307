//
// Back link
//
.m-backlink {
  @include u-baseline(2, padding-top);
  @include u-baseline(2, padding-bottom);
  background-color: $backlink-background;
  border-bottom: 1px solid $backlink-border-color;

  @include breakpoint(large) {
    @include u-baseline(4, padding-top);
    @include u-baseline(4, padding-bottom);
  }

  .c-icon {
    @include u-baseline(3, width);
    @include u-baseline(3, height);
    @include u-baseline(3, margin-right);
    transform: scaleX(-1);
  }

  a {
    @include font($font-display-16);
    font-weight: bold;

    &:hover {
      color: $global-accent-color;
    }
  }
}










/** ***********
 * Grid wrap
 */
.m-backlink__wrap {
  @include xy-grid-container;
  @include xy-grid;
}









/** ***********
 * Inner container
 */
.m-backlink__inner {
  @include xy-cell;

  @include breakpoint(large) {
    @include xy-cell;
  }
}
