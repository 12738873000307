//
// Page intro for homepage
//
.o-intro-home {
  @include u-module-margin;

  .m-introbox__header {
    @include u-baseline(2, padding-left);
    @include u-baseline(2, padding-right);

    @include breakpoint(large) {
      padding-left: 0;
    }
  }
}







/** ***********
 * Full width intro
 */
.o-intro-home__intro {
  background-color: $megaheader-intro-color;

  @include breakpoint(large) {
    @include u-baseline(4, padding-bottom);
  }
}







/** ***********
 * Inner grid
 */
.o-intro-home__inner {
  @include xy-grid-container;
  @include xy-grid;
  overflow: hidden;
  position: relative;

  @include breakpoint(large) {
    overflow: visible;
  }
}











/** ***********
 * Content
 */
.o-intro-home__content {
  @include xy-cell;
  @include u-baseline(19, padding-top);
  position: relative;
  z-index: 2;

  @include breakpoint(large) {
    @include xy-cell(6);
    @include u-baseline(5, padding-top);
  }

  @include breakpoint(xlarge) {
    @include xy-cell(6);
  }
}





/** ***********
 * Lead
 */
.o-intro-home__lead {
  @include xy-cell;
  @include u-baseline(3, padding-top);

  @include breakpoint(large) {
    @include xy-cell(6);
    @include u-baseline(5, padding-top);
  }

  @include breakpoint(xlarge) {
    @include xy-cell(6);
    @include u-baseline(7, padding-top);
  }
}





/** ***********
 * Media wrapper
 */
.o-intro-home__media {
  @include xy-cell($gutters: 0);
  @include u-baseline(-2, margin-left);
  position: absolute;
  width: calc(100% + #{rem-calc($global-baseline * 2)});
  z-index: 1;

  @include breakpoint(large) {
    @include xy-cell(6);
    @include u-baseline(2, margin-left);
    margin-left: 0;
    position: relative;
    // width: 100%;
  }

  .c-image img {
    object-fit: cover;
    width: 100%;
    height: 100%;

    @include breakpoint(large) {
      object-fit: initial;
    }
  }

}








/** ***********
 * Image
 */
.o-intro-home__img {
  @include breakpoint(large) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }
}
