//
// Contacts
//
.o-contacts {
  @include u-baseline(3, padding-top);
  @include u-baseline(3, padding-bottom);
  background-color: $contacts-background;
  background-image: url(../img/footer-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;

  @include breakpoint($contacts-breakpoint) {
    @include u-baseline(7, padding-top);
    @include u-baseline(5, padding-bottom);
  }
}









/** ***********
 * Grid wrapper
 */
.o-contacts__wrapper {
  @include xy-grid-container;
  @include xy-grid;
}








/** ***********
 * Header
 */
.o-contacts__header {
  @include xy-cell;
  @include u-baseline(3, margin-bottom);

  @include breakpoint($contacts-breakpoint) {
    @include xy-cell(3);
    margin-bottom: 0;
  }

  .c-icon {
    @include u-baseline(7, width);
    @include u-baseline(7, height);
    color: $contacts-icon-color;
  }
}










/** ***********
 * Headline
 */
.o-contacts__headline {
  @include font($font-display-48-wide, $family: true);
  @include u-baseline(7, margin-bottom);
  @include u-baseline(3, padding-right);
  color: $contacts-headline-color;
  font-weight: font(base, 'semibold');
  margin-top: 0;
}










/** ***********
 * Content wrapper
 */
.o-contacts__content {
  @include xy-cell;

  @include breakpoint(large) {
    @include xy-cell;
  }

  @include breakpoint($contacts-breakpoint) {
    @include xy-cell(9);
  }
}










/** ***********
 * Contact items
 */
.o-contacts__items {
  @include breakpoint(large) {
    @include flex;
    @include u-baseline(-2, margin-left);
    @include u-baseline(-2, margin-right);
  }

  @include breakpoint(xlarge) {
    @include u-baseline(-1.5, margin-left);
    @include u-baseline(-1.5, margin-right);
  }
}








/** ***********
 * Item
 */
.o-contacts__item {
  @include xy-cell($gutters: 0);
  @include u-baseline(4, margin-bottom);

  @include breakpoint(large) {
    @include xy-cell(4, $gutters: rem-calc(16));
    @include flex;
    margin-bottom: 0;
  }
}
