//
// Teaser blocks
//
.o-teaserblocks {
  @include xy-grid-container;
  @include xy-grid;
  @include u-module-margin;
  position: relative;
  z-index: 2;
}









/** ***********
 * Inner container
 */
.o-teaserblocks__inner {
  @include xy-cell;

  @include breakpoint(large) {
    @include xy-cell;
  }
}






.o-teaserblocks__items {
  @include xy-grid-container;
  @include xy-grid;
  @include xy-gutters($negative: true);
}










/** ***********
 * Item
 *
 * @use vendor/_family.scss
 */
.o-teaserblocks__item {
  @include xy-cell(12, $gutters: 0);
  border: $teaserblocks-border;
  border-bottom: none;

  @include last-child() {
    border-bottom: $teaserblocks-border;
  }

  @include breakpoint(medium) {
    @include xy-cell(6, $gutters: 0);
    @include flex;
    border: $teaserblocks-border;
    border-right: none;
    border-bottom: none;

    @include even() {
      border-right: $teaserblocks-border;
    }

    @include last(2) {
      border-bottom: $teaserblocks-border;
    }
  }

  @include breakpoint(xlarge) {
    @include xy-cell(3, $gutters: 0);
    border: $teaserblocks-border;
    border-right: none;

    @include even() {
      border-right: none;
    }

    @include last-child() {
      border-right: $teaserblocks-border;
    }
  }
}
