//
// Page intro for child pages
//
.o-intro-child {
  @include xy-grid-container;
  @include xy-grid;
  @include u-module-margin;
}









/** ***********
 * Modifier: Body is wider than usual
 */
.o-intro-child--is-wide {
  .o-intro-child__content {
    @include breakpoint(xlarge) {
      @include xy-cell(8);
    }
  }

  .o-intro-child__module {
    @include breakpoint(xlarge) {
      @include xy-cell(3);
      @include xy-cell-offset(1);
    }
  }
}










/** ***********
 * Content container
 */
.o-intro-child__content {
  @include xy-cell;
  @include u-baseline(10, padding-top);

  @include breakpoint(large) {
    @include xy-cell(7);
    @include u-baseline(7, padding-top);
    margin-bottom: 0;
  }

  @include breakpoint(xlarge) {
    @include xy-cell(6);
  }
}








/** ***********
 * Title
 */
.o-intro-child__title {
  @include font($font-display-28, $family: true);
  @include u-baseline(3, margin-bottom);
  font-weight: font(base, 'semibold');
  margin-top: 0;

  @include breakpoint(medium) {
    @include font($font-display-32);
  }

  @include breakpoint(xlarge) {
    @include font($font-display-48);
    @include u-baseline(4, margin-bottom);
  }

  span {
    color: $intro-child-title-color;
  }
}






/** ***********
 * Lead text
 */
.o-intro-child__lead {
  @include u-baseline(4, margin-bottom);
}








/** ***********
 * Body
 */
.o-intro-child__body {
  @include u-baseline(4, margin-bottom);

  @include breakpoint(large) {
    @include font($font-base-18-wide);
    margin-bottom: 0;
  }

  p {
    @include u-baseline(3, margin-bottom);
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: $global-link-color;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}










/** ***********
 * Module container
 */
.o-intro-child__module {
  @include xy-cell(11);
  @include xy-cell-offset(1);

  @include breakpoint(large) {
    @include xy-cell(4);
    @include xy-cell-offset(0);
    @include u-baseline(12, padding-top);
  }

  @include breakpoint(xlarge) {
    @include xy-cell(5);
    @include xy-cell-offset(1);
  }
}
