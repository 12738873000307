
/// @example
///   .element {
///     color: palette(ui, 'brand');
///

$palettes: (
  brand: (
    'glacier':         #77a3bc,
    'boticelli':       #cfdfe6,
    'wedgewood':       #5487a4,
    'ming':            #3f6b84,
    'oxford':          #354955,
    'tepapa':          #1e3542,
    'cello':           #1b4861
  ),
  ui: (
    'dark':            #0b0b0b,
    'light':           #fff,
    'alabaster':       #f9f9f9,
    'athens':          #f1f4f4,
    'alto':            #d8d8d8,
    'dove':            #707070,
    'cod':             #1a1919
  )
);




/// Function to parse color palettes
@function palette($palette, $tone: 'brand') {
  @return map-get(map-get($palettes, $palette), $tone);
}




/// Generating color and background Classes
/// from color map
$color-theme-list: (
  glacier: brand,
  boticelli: brand,
  wedgewood: brand,
  ming: brand,
  oxford: brand,
  tepapa: brand,
  dark: ui,
  light: ui,
  athens: ui,
  alto: ui,
  cod: ui
) !default;




/// Classes to use in templates
@mixin color-theme($color, $section) {
  .color--#{$color} {
    color: palette($section, #{$color});
  }

  .background--#{$color} {
    background-color: palette($section, #{$color});
  }
}

@each $key, $value in $color-theme-list {
  @include color-theme($key, $value);
}
