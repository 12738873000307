//
// Images
//
.m-images {
  @include xy-grid-container;
  @include xy-grid;
  @include u-module-margin;
  @include flex-align(center, top);
  position: relative;
  z-index: 2;
}










/** ***********
 * Item
 */
.m-images__item {
  @include xy-cell;
  @include u-baseline(4, margin-bottom);

  @include breakpoint(large) {
    @include xy-cell(10);
  }

  @include not-unique() {
    @include breakpoint(large) {
      @include xy-cell(6);
    }
  }
}
