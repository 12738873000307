//
// Teaser
//
.m-teaser {
  @include xy-grid-container;
  @include xy-grid;
  @include u-module-margin;
  padding: 0 !important;
  background-color: $teaser-background;
}











/** ***********
 * Media / Image
 */
.m-teaser__media {
  @include xy-cell($gutters: 0);

  @include breakpoint(large) {
    @include xy-cell(8, $gutters: 0);
  }
}










/** ***********
 * Content
 */
.m-teaser__content {
  @include xy-cell($gutters: 0);
  @include u-baseline(3, padding);

  @include breakpoint(large) {
    @include xy-cell(4, $gutters: 0);
    @include flex;
  }

  @include breakpoint(xlarge) {
    @include u-baseline(9, padding-top);
    @include u-baseline(9, padding-bottom);
    @include u-baseline(7, padding-left);
    @include u-baseline(7, padding-right);
  }
}









/** ***********
 * Content card
 */
.m-teaser__card {
  @include breakpoint(large) {
    @include flex;
    @include u-baseline(6, padding-top);
    @include u-baseline(6, padding-bottom);
    @include u-baseline(3, padding-left);
    @include u-baseline(3, padding-right);
    flex-direction: column;
    border: $teaser-border;
  }

  .c-button {
    @include u-baseline(3, margin-top);

    @include breakpoint(xlarge) {
      @include u-baseline(11, margin-top);
    }
  }
}









/** ***********
 * Text body
 */
.m-teaser__body {
  @include font($font-base-16);

  @include breakpoint(large) {
    flex: 1 0 auto;
  }
}









/** ***********
 * Headline
 */
.m-teaser__headline {
  @include font($font-display-28, $family: true);
  @include u-baseline(3, margin-bottom);
  margin-top: 0;

  @include breakpoint(xlarge) {
    @include font($font-display-32);
    @include u-baseline(5, margin-bottom);
  }

  a {
    color: $teaser-heading-color;

    &:hover {
      color: $global-body-color;
    }
  }
}
