//
// Downloads
//
.m-downloads {
  @include xy-grid-container;
  @include xy-grid;
  @include u-module-margin;
  @include font($font-display-16, $family: true);
  font-weight: bold;
}








/** ***********
 * Inner container
 */
.m-downloads__inner {
  @include xy-cell;

  @include breakpoint(medium) {
    @include xy-cell(10);
  }

  @include breakpoint(large) {
    @include xy-cell(9);
  }
}









/** ***********
 * Headline
 */
.m-downloads__headline {
  @include u-baseline(3, margin-bottom);
  margin-top: 0;
}









/** ***********
 * List
 */
.m-downloads__list {
  list-style: none;
  margin: 0;
  padding: 0;
}










/** ***********
 * List item
 */
.m-downloads__item {
  position: relative;

  &:before {
    @include u-baseline(3, height);
    @include u-baseline(3, width);
    background: url(../svg/svg_icon-download.svg);
    background-size: 100% 100%;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
  }

  &:not(:last-child) {
    @include u-baseline(2, margin-bottom);
  }
}








/** ***********
 * Link
 */
.m-downloads__link {
  @include u-baseline(4, padding-left);
  color: $global-accent-color;
  display: inline-block;
  position: relative;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}
