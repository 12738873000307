/// Web fonts

$fonts-path: '/assets/fonts';

// /* exo-2-300 - latin */
// @font-face {
//   font-family: 'Exo';
//   font-style: normal;
//   font-weight: 300;
//   font-display: swap;
//   src: url('#{$fonts-path}/exo-2-v8-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
//        url('#{$fonts-path}/exo-2-v8-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
// }
//
// /* exo-2-regular - latin */
// @font-face {
//   font-family: 'Exo';
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
//   src: url('#{$fonts-path}/exo-2-v8-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
//        url('#{$fonts-path}/exo-2-v8-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
// }
//
// /* exo-2-600 - latin */
// @font-face {
//   font-family: 'Exo';
//   font-style: normal;
//   font-weight: 600;
//   font-display: swap;
//   src: url('#{$fonts-path}/exo-2-v8-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
//        url('#{$fonts-path}/exo-2-v8-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
// }
//
// /* exo-2-700 - latin */
// @font-face {
//   font-family: 'Exo';
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
//   src: url('#{$fonts-path}/exo-2-v8-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
//        url('#{$fonts-path}/exo-2-v8-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
// }
//



/* exo-300 - latin */
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('#{$fonts-path}/exo-v9-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('#{$fonts-path}/exo-v9-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* exo-regular - latin */
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('#{$fonts-path}/exo-v9-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('#{$fonts-path}/exo-v9-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* exo-600 - latin */
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('#{$fonts-path}/exo-v9-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('#{$fonts-path}/exo-v9-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* exo-700 - latin */
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('#{$fonts-path}/exo-v9-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('#{$fonts-path}/exo-v9-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


/* archivo-regular - latin */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('#{$fonts-path}/archivo-v6-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('#{$fonts-path}/archivo-v6-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* archivo-italic - latin */
@font-face {
  font-family: 'Archivo';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('#{$fonts-path}/archivo-v6-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('#{$fonts-path}/archivo-v6-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* archivo-700 - latin */
@font-face {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('#{$fonts-path}/archivo-v6-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('#{$fonts-path}/archivo-v6-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* archivo-700italic - latin */
@font-face {
  font-family: 'Archivo';
  font-style: italic;
  font-weight: bold;
  font-display: swap;
  src: url('#{$fonts-path}/archivo-v6-latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('#{$fonts-path}/archivo-v6-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}



/// Font settings
$font-stack: ('Arial', 'Helvetica', sans-serif);

$fonts: (
  base: (
    'preload':        (sans-serif),
    'base':           (sans-serif),
    'stack':          $font-stack,
    'body':           ('Archivo', #{$font-stack}),
    'copy':           ('Archivo', #{$font-stack}),
    'display':        ('Exo', #{$font-stack}),
    'size':           100%,
    'line-height':    1.5,
    'light':          300,
    'semibold':       600
  ),
  size: (
    '12':             12,
    '14':             14,
    '16':             16,
    '18':             18,
    '24':             24,
    '28':             28,
    '32':             32,
    '48':             48
  )
);




/// Function to parse font map
@function font($font, $type: 'base') {
  @return map-get(map-get($fonts, $font), $type);
}


$font-base-12: (
  size:          '12',
  line-height:   24,
  family:        'base'
);

$font-display-14: (
  size:          '14',
  line-height:   24,
  family:        'display'
);

$font-base-16: (
  size:          '16',
  line-height:   24,
  family:        'base'
);

$font-display-16: (
  size:          '16',
  line-height:   24,
  family:        'display'
);

$font-base-18-wide: (
  size:          '18',
  line-height:   32,
  family:        'base'
);

$font-display-18: (
  size:          '18',
  line-height:   24,
  family:        'display'
);


$font-display-24-wide: (
  size:          '24',
  line-height:   40,
  family:        'display'
);


$font-display-28: (
  size:          '28',
  line-height:   32,
  family:        'display'
);

$font-display-32: (
  size:          '32',
  line-height:   40,
  family:        'display'
);

$font-display-32-wide: (
  size:          '32',
  line-height:   48,
  family:        'display'
);

$font-display-48: (
  size:          '48',
  line-height:   56,
  family:        'display'
);

$font-display-48-wide: (
  size:          '48',
  line-height:   64,
  family:        'display'
);



// Mixin to generate font sizes and baseline metrics
//
// @example:
//   .module {
//     @include font($font-options-s);
//   }
$options: (
  size:          m,
  line-height:   24,
  family:        'copy'
);




@mixin font($options, $family: false) {
  $size: font(size, map-get($options, size));

  @include u-font-baseline($size, map-get($options, line-height));

  @if $family {
    font-family: font(base, map-get($options, family));
  }
}


// Replace basic font with loaded webfont
@mixin font-replace($family) {
  font-family: font(base, $family);
}
