//
// Page header
//
.o-intro-parent {
  @include xy-grid-container;
  @include xy-grid;
  @include u-module-margin;
  // overflow: hidden;
}








/** ***********
 * Left content area
 */
.o-intro-parent__content {
  @include xy-cell;

  @include breakpoint(large) {
    @include xy-cell(6, $gutters: 0);
  }

  @include breakpoint(xlarge) {
    @include xy-cell(5, $gutters: 0);
  }
}








/** ***********
 * Lead text
 */
.o-intro-page__lead {
  @include u-baseline(4, margin-bottom);

  @include breakpoint(large) {
    @include u-baseline(2, padding-left);
    @include u-baseline(6, padding-right);
    margin-bottom: 0;
  }
}









/** ***********
 * Content inner white box
 */
.o-intro-parent__content-inner {
  @include u-baseline(3, margin-bottom);
  @include u-baseline(2, padding-bottom);
  background-color: $intro-parent-background;
  position: relative;

  &:before {
    background-color: $intro-parent-background;
    content: '';
    position: absolute;
    left: -(rem-calc(3000));
    width: rem-calc(3000);
    height: 100%;
    top: 0;
    bottom: 0;
  }

  &:after {
    background-color: $intro-parent-background;
    content: '';
    position: absolute;
    right: -(rem-calc(3000));
    width: rem-calc(3000);
    height: 100%;
    top: 0;
    bottom: 0;

    @include breakpoint(large) {
      display: none;
    }
  }

  @include breakpoint(large) {
    @include u-baseline(2, padding-left);
    @include u-baseline(8, padding-right);
    @include u-baseline(10, padding-bottom);
    @include u-baseline(7, margin-bottom);
    position: relative;
  }
}









/** ***********
 * Media column
 */
.o-intro-parent__media {
  @include xy-cell;

  @include breakpoint(large) {
    @include xy-cell(6, $gutters: 0);
  }

  @include breakpoint(xlarge) {
    @include xy-cell(7, $gutters: 0);
  }
}











/** ***********
 * Image container
 */
.o-intro-parent__image {
  @include u-baseline(5, margin-bottom);

  @include breakpoint(xlarge) {
    @include u-baseline(8, margin-bottom);
    width: calc(100% + #{rem-calc(80)});
  }

  @include breakpoint(xxlarge) {
    width: calc(100% + #{rem-calc(180)});
  }
}










/** ***********
 * Extended text
 */
.o-intro-parent__extended {
  @include breakpoint(large) {
    @include font($font-base-18-wide);
    @include u-baseline(3, padding-top);
    @include u-baseline(3, padding-left);
    @include u-baseline(2, padding-right);
  }

  @include breakpoint(xlarge) {
    @include font($font-base-18-wide);
    @include u-baseline(7, padding-top);
    @include u-baseline(11, padding-left);
    padding-right: 0;
  }

  p {
    @include u-baseline(3, margin-bottom);
    margin-top: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}











/** ***********
 * Subnavigation
 */
.o-intro-parent__subnav {
  @include u-baseline(4, padding-left);
  @include u-baseline(2, padding-right);

  @include breakpoint(large) {
    @include u-baseline(3, padding-right);
  }

  @include breakpoint(xlarge) {
    @include u-baseline(27, padding-left);
    @include u-baseline(8, padding-right);
  }
}
