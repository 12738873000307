// Button and button like links
//

.c-button {
  @include font($font-display-16);
  @include u-baseline(7, height);
  @include u-baseline(3, padding-right);
  @include u-baseline(3, padding-left);
  @include u-baseline(1.6, padding-top);
  appearance: none;
  background-color: $button-background;
  border: 3px solid transparent;
  border-radius: 0;
  color: $button-color;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;

  &:hover,
  &:active {
    background-color: $button-hover-background;
  }

  .c-icon {
    @include u-baseline(3, width);
    @include u-baseline(3, height);
    @include u-baseline(2, margin-right);
  }
}






/** ***********
 * Modifer: Button is plain
 */
.c-button--plain {
  background-color: transparent;
  color: $global-body-color;
  height: auto;
  padding: 0;

  &:hover {
    background-color: transparent;

    .c-icon {
      color: $global-body-color;
    }
  }

  &.c-button--has-icon {
    padding-left: 0;
  }

  .c-icon {
    color: $global-accent-color;
  }
}






.c-button--small {
  @include font($font-display-14);
  @include u-baseline(5, height);
  padding-top: 0;
}






/** ***********
 * Modifier: Button has icon
 */
.c-button--has-icon {
  @include u-baseline(2, padding-left);
  overflow: hidden;

  .c-icon {
    transform: translatey(1px);
    float: left;
  }

  .c-button__inner {
    @include u-baseline(5, margin-left);
    display: block;
  }
}
