//
// Intro list
//
.m-introlist {
  @include u-baseline(5, padding-left);
  border-left: 1px solid $introlist-border-color;
  list-style: none;
  margin: 0;
  position: relative;

  &:before,
  &:after {
    border: 1px solid $introlist-border-color;
    border-radius: 50%;
    content: '';
    display: block;
    position: absolute;
    left: -(rem-calc(5));
    width: rem-calc(9);
    height: rem-calc(9);
  }

  &:before {
    background-color: $introlist-circle-background;
    top: 0;
  }

  &:after {
    background-color: $introlist-border-color;
    bottom: 0;
  }
}









/** ***********
 * List item
 */
.m-introlist__item {
  @include font($font-display-18, $family: true);
  @include u-baseline(3, margin-bottom);
  font-weight: bold;
  text-transform: uppercase;

  @include breakpoint(large) {
    @include u-baseline(7, margin-bottom);
  }

  &:last-child {
    margin-bottom: 0;
  }
}
