//
// Site Footer
//
.o-footer {
  @include u-baseline(3, padding-top);
  @include u-baseline(6, padding-bottom);
  background-color: $footer-background;
  color: $footer-color;

  @include breakpoint(large) {
    @include u-baseline(5, padding-top);
  }

  a {
    color: $footer-link-color;
  }
}










/** ***********
 * Grid wrapper
 */
.o-footer__wrap {
  @include xy-grid-container;
  @include xy-grid;

  @include breakpoint(large) {
    @include flex-align(left, middle);
  }
}








/** ***********
 * Inner container
 */
.o-footer__inner {
  @include xy-cell;

  @include breakpoint(large) {
    @include xy-cell;
    @include flex;
    width: 100%;
  }
}









/** ***********
 * Credits
 */
.o-footer__credits {
  @include xy-cell;
  @include font($font-base-16);
  order: 3;
  text-align: center;

  @include breakpoint(large) {
    @include xy-cell(3);
    order: 1;
    text-align: left;
  }
}









/** ***********
 * Menu
 */
.o-footer__menu {
  @include xy-cell;
  font-size: 0;
  order: 2;
  text-align: center;

  @include breakpoint(large) {
    @include xy-cell(6);
  }

  a {
    @include font($font-display-16, $family: true);
    @include u-baseline(2, margin-left);
    @include u-baseline(2, margin-right);
    @include u-baseline(2, margin-bottom);
    display: inline-block;
    font-weight: bold;
    text-decoration: underline;
    text-transform: uppercase;

    @include breakpoint(large) {
      @include font($font-display-18);
    }

    @include breakpoint(xlarge) {
      margin-bottom: 0;
    }

    &:hover {
      text-decoration: none;
    }
  }
}









/** ***********
 * Language switch
 */
.o-footer__lang {
  @include xy-cell;
  @include u-baseline(3, margin-bottom);
  order: 1;
  text-align: center;

  @include breakpoint(large) {
    @include xy-cell(3);
    margin-bottom: 0;
    order: 3;
    text-align: right;
  }
}
