//
// Language switch
//
.m-languages {
  @include u-baseline(6, height);
  @include u-baseline(1.5, padding-top);
  @include u-baseline(2, padding-left);
  @include u-baseline(2, padding-right);
  background-color: $languages-background;
  display: inline-block;
  font-size: 0;

  .c-icon {
    @include u-baseline(2, margin-right);
    display: inline-block;
    vertical-align: middle;
    width: rem-calc(21);
    height: rem-calc(15);
  }
}







.m-languages__select {
  @include font($font-base-16);
  appearance: none;
  background-color: $languages-background;
  border: none;
  color: $languages-color;
  cursor: pointer;
  vertical-align: middle;
}
