//
// Contact
//
.m-contact {
  @include flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}










/** ***********
 * Modifier: Light background
 */
.m-contact--light {
  .m-contact__content {
    background-color: $contact-background-light;
    color: palette(ui, 'cod');
  }

  .m-contact__title {
    color: $contact-title-color-light;
  }

  .m-contact__switch {
    color: $contact-title-color-light;
  }
}











/** ***********
 * Switch link
 */
.m-contact__switch {
  @include u-baseline(3, top);
  @include u-baseline(2, right);
  @include font($font-base-12);
  appearance: none;
  background: none;
  border: none;
  color: $contact-color;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  text-decoration: underline;
  text-transform: none;

  &:hover {
    text-decoration: none;
  }
}







/** ***********
 * Content wrapper
 */
.m-contact__content {
  @include flex;
  @include font($font-base-16);
  @include u-baseline(3, padding-top);
  @include u-baseline(2, padding-left);
  @include u-baseline(2, padding-right);
  background-color: $contact-background;
  color: $contact-color;
  flex-direction: column;
  flex: 1 0 auto;

  p {
    @include u-baseline(3, margin-bottom);
    margin-top: 0;
  }

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}







/** ***********
 * Title
 */
.m-contact__title {
  @include font($font-display-16, $family: true);
  @include u-baseline(12, max-width);
  @include u-baseline(1, margin-bottom);
  margin-top: 0;
}









/** ***********
 * Postal address
 */
.m-contact__address {
  flex: 1 0 auto;
}










/** ***********
 * Icons
 */
.m-contact__icons {
  @include flex;
}










/** ***********
 * Icon
 */
.m-contact__icon {
  @include u-baseline(6, height);
  background-color: $contact-icon-background;
  color: $contact-icon-color;
  flex: 1 0 auto;

  &:not(:last-child) {
    border-right: 1px solid $contact-icon-border;
  }

  a {
    @include flex;
    @include flex-align(center, middle);
    color: inherit;
    height: 100%;

    &:hover {
      background-color: $contact-icon-hover;
    }
  }

  .c-icon {
    @include u-baseline(3, width);
    @include u-baseline(3, height);
  }
}
