//
// Cookie consent
//
.m-consent {
  // background-color: $global-primary-color;
  background-color: $consent-background;
  box-shadow: 0 0 16px rgba(0, 0, 0, .2);
  color: palette(ui, 'dark');
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;

  &.is-active {
    display: block;
  }
}

.fonts--loaded .m-consent {
  @include font-replace(copy);
}










/** ***********
 * Inner wrapper
 */
.m-consent__inner {
  @include xy-grid-container;
  @include xy-grid;
  @include flex-align(left, middle);
  @include u-baseline(2, padding-top);
  @include u-baseline(2, padding-bottom);
}







/** ***********
 * Body
 */
.m-consent__body {
  @include xy-cell;
  @include font($font-base-12);
  @include u-baseline(2, margin-bottom);

  @include breakpoint(medium) {
    @include xy-cell(7, $breakpoint: medium);
    margin-bottom: 0;
  }

  @include breakpoint(large) {
    @include xy-cell(7, $breakpoint: large);
    @include font($font-display-14);
  }

  @include breakpoint(xlarge) {
    @include xy-cell(4, $breakpoint: xlarge);
  }

  a {
    color: inherit;
    border-bottom: 2px solid currentColor;
    font-weight: bold;

    &:hover {
      border-color: transparent;
    }
  }

  p {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
}







/** ***********
 * Button
 */
.m-consent__button {
  @include xy-cell;
  text-align: right;

  @include breakpoint(medium) {
    @include xy-cell(5, $breakpoint: medium);
  }

  @include breakpoint(large) {
    @include xy-cell(5, $breakpoint: large);
    text-align: right;
  }

  @include breakpoint(xlarge) {
    @include xy-cell(8, $breakpoint: xlarge);
  }
}
