//
// Pie chart
//
.m-piechart {
  @include flex;
  margin: 0 auto;
  position: relative;
  width: $piechart-size-small;
  height: $piechart-size-small;

  @include breakpoint(medium) {
    margin: 0;
    width: $piechart-size-large;
    height: $piechart-size-large;
  }
}











/** ***********
 * The chart
 */
.m-piechart__chart {
  border-radius: 50%;
  height: 0;
  padding-top: 50%;
  float: left;
  width: 100%;
  height: 100%;
}








/** ***********
 * Content
 */
.m-piechart__content {
  @include flex;
  @include flex-align(center, middle);
  @include u-baseline(2, top);
  @include u-baseline(2, left);
  @include u-baseline(2, right);
  @include u-baseline(2, bottom);
  background-color: white;
  border-radius: 50%;
  flex-direction: column;
  position: absolute;
  text-align: center;
}









/** ***********
 * Title
 */
.m-piechart__title {
  @include font($font-display-18, $family: true);
  font-weight: bold;

  @include breakpoint(medium) {
    @include font($font-display-24-wide);
  }
}









/** ***********
 * Value
 */
.m-piechart__value {
  @include font($font-display-28, $family: true);
  color: $piechart-value-text-color;
  font-weight: font(base, 'light');

  @include breakpoint(medium) {
    @include font($font-display-32-wide);
  }
}
