//
// Page header organism
//


/** ***********
 * Base styles
 */
.o-header {
  background-color: $header-background-color;
  border-bottom: 1px solid palette(ui, 'alto');
  position: relative;
}







/** ***********
 * Grid wrapper
 */
.o-header__wrapper {
  @include xy-grid-container;
  @include xy-grid;
  @include u-baseline(1.25, margin-bottom);
  @include flex-align(left, middle);
  padding-top: rem-calc(14);
  z-index: index($elements, header);

  @include breakpoint($global-navigation-breakpoint) {
    @include u-baseline(3, padding-top);
    @include u-baseline(3, margin-bottom);
  }
}







/** ***********
 * Logo
 */
.o-header__brand {
  @include xy-cell(shrink);
  text-align: center;
  position: relative;
  z-index: index($elements, branding);

  @include breakpoint($global-navigation-breakpoint) {
    @include xy-cell(shrink);
    text-align: left;
  }

  a {
    display: block;
    position: relative;
  }

  h1 {
    display: inline;
    margin: 0;
  }

  svg,
  img {
    // @include u-baseline(-1, margin-left);
    display: block;
    margin: 0 auto;
    width: rem-calc(57);
    height: rem-calc(40);

    @include breakpoint($global-navigation-breakpoint) {
      width: rem-calc(157);
      height: rem-calc(114);
    }
  }

  img {
    position: absolute;
    top: 0;
  }

  span {
    @include u-a11y-hidden;
  }
}






/** ***********
 * Menu
 */
.o-header__menu {
  @include xy-cell(auto);
  order: 1;
  text-align: right;
  width: rem-calc(60);


  @include breakpoint($global-navigation-breakpoint) {
    @include xy-cell(auto);
    order: 2;
  }
}
