//
// Text block
//
.m-text {
  @include xy-grid-container;
  @include xy-grid;
  @include u-module-margin;
  position: relative;
  z-index: 2;
}








/** ***********
 * Inner container
 */
.m-text__inner {
  @include xy-cell;

  @include breakpoint(medium) {
    @include xy-cell(10);
  }

  @include breakpoint(large) {
    @include xy-cell(9);
  }
}
